@tailwind base;
@tailwind components;
@tailwind utilities;

.qr-reader>section {
    position: unset !important;
    width: 100% !important;
    height: 100% !important;
    padding-top: unset !important;
}

.qr-reader>section>video {
    position: unset !important;
    width: 100% !important;
    height: 100% !important;
}

.react-pdf__Page__svg {
    width: 100% !important;
    height: 100% !important;
    overflow: unset !important;
}
